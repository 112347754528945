// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notifications_wrapper__EHJBh {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.Notifications_wrapperNotification__zdDWZ {
  width: 320px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px -3px gray;
}
.Notifications_wrapperNotification__zdDWZ .Notifications_message__lsvIn {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.Notifications_hide__CHHdj {
  display: none;
  transition: 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/components/Notifications/Notifications.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,YAAA;AAAF;;AAEA;EAIE,YAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;AAFF;AAKE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;AAHJ;;AAMA;EACE,aAAA;EACA,gBAAA;AAHF","sourcesContent":[".wrapper {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  z-index: 100;\n}\n.wrapperNotification {\n  //position: fixed;\n  //top: 50px;\n  //left: calc(50% - 170px);\n  width: 320px;\n  min-height: 320px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n  padding: 12px;\n  background: white;\n  border-radius: 10px;\n  box-shadow: 0 0 10px -3px gray;\n  //z-index: 100;\n\n  .message {\n    text-align: center;\n    font-size: 18px;\n    font-weight: 400;\n  }\n}\n.hide {\n  display: none;\n  transition: .5s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Notifications_wrapper__EHJBh`,
	"wrapperNotification": `Notifications_wrapperNotification__zdDWZ`,
	"message": `Notifications_message__lsvIn`,
	"hide": `Notifications_hide__CHHdj`
};
export default ___CSS_LOADER_EXPORT___;
