// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserReviews_cart__Hxe7-{
    width: 440px;
    height: 140px;
    background-color: lightgrey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.UserReviews_wrapper__fBNeW{
    margin: 30px 0 30px 0;
}


@media (max-width: 370px){
    .UserReviews_cart__Hxe7-{
        width: 200px;
        height: auto;
        font-size: 12px;
    }
    .UserReviews_cart__Hxe7- img{
        width: 30%;
    }
}

@media (max-width: 990px){
    .UserReviews_cart__Hxe7-{
        width: 300px;
        height: auto;
        font-size: 12px;
    }
    .UserReviews_cart__Hxe7- img{
        width: 30%;
    }
}

@media (max-width: 770px) {
    .UserReviews_cart__Hxe7-{
        width: 250px;
    }
}

@media (max-width: 530px) {
    .UserReviews_cart__Hxe7-{
        width: 200px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/UserReviews/UserReviews.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI;QACI,YAAY;QACZ,YAAY;QACZ,eAAe;IACnB;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;QACZ,eAAe;IACnB;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".cart{\n    width: 440px;\n    height: 140px;\n    background-color: lightgrey;\n    border-radius: 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n\n.wrapper{\n    margin: 30px 0 30px 0;\n}\n\n\n@media (max-width: 370px){\n    .cart{\n        width: 200px;\n        height: auto;\n        font-size: 12px;\n    }\n    .cart img{\n        width: 30%;\n    }\n}\n\n@media (max-width: 990px){\n    .cart{\n        width: 300px;\n        height: auto;\n        font-size: 12px;\n    }\n    .cart img{\n        width: 30%;\n    }\n}\n\n@media (max-width: 770px) {\n    .cart{\n        width: 250px;\n    }\n}\n\n@media (max-width: 530px) {\n    .cart{\n        width: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cart": `UserReviews_cart__Hxe7-`,
	"wrapper": `UserReviews_wrapper__fBNeW`
};
export default ___CSS_LOADER_EXPORT___;
