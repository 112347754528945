// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainPage_wrapper_mainPage__noimG{
    height: 100vh;
    display: flex;
    flex-direction: column;

}

.MainPage_main_mainPage__u3QRN{
    flex: 1 1 auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/MainPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".wrapper_mainPage{\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n\n}\n\n.main_mainPage{\n    flex: 1 1 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper_mainPage": `MainPage_wrapper_mainPage__noimG`,
	"main_mainPage": `MainPage_main_mainPage__u3QRN`
};
export default ___CSS_LOADER_EXPORT___;
