// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Block_cart__oBldo {
  width: 430px;
  height: 100px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.Block_wrapper__djAAq {
  margin: 30px 0 30px 0;
}

@media (max-width: 390px) {
  .Block_cart__oBldo {
    width: 200px;
    height: auto;
    border: 1px solid lightgrey;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }
}


@media (max-width: 1200px) {
  .Block_cart__oBldo {
    width: 70%;
    height: auto;
    border: 1px solid lightgrey;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MultiCarousel/Block.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;;;AAGA;EACE;IACE,UAAU;IACV,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".cart {\n  width: 430px;\n  height: 100px;\n  border: 1px solid lightgrey;\n  border-radius: 5px;\n  text-align: center;\n  padding: 10px;\n}\n\n.wrapper {\n  margin: 30px 0 30px 0;\n}\n\n@media (max-width: 390px) {\n  .cart {\n    width: 200px;\n    height: auto;\n    border: 1px solid lightgrey;\n    border-radius: 5px;\n    text-align: center;\n    padding: 10px;\n  }\n\n  h3 {\n    font-size: 20px;\n  }\n\n  p {\n    font-size: 12px;\n  }\n}\n\n\n@media (max-width: 1200px) {\n  .cart {\n    width: 70%;\n    height: auto;\n    border: 1px solid lightgrey;\n    border-radius: 5px;\n    text-align: center;\n    padding: 10px;\n  }\n\n  h3 {\n    font-size: 20px;\n  }\n\n  p {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cart": `Block_cart__oBldo`,
	"wrapper": `Block_wrapper__djAAq`
};
export default ___CSS_LOADER_EXPORT___;
